import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderHtmlBlocks } from "../helpers/render"
import Heading from "../components/Elements/Heading"
import Breadcrumbs from "../components/Breadcrumbs"
import { getCustomStyles } from "../helpers/styles"

const NewsTemplate = ({ data: { currentNews }, pageContext }) => {
  const seo = currentNews?.seo
  const settings = currentNews?.settings

  return (
    <Layout>
      <Seo
        title={seo?.title || currentNews.title}
        description={seo?.metaDescription || currentNews.excerpt}
        keywords={seo?.metaKeywords || ""}
      />
      {getCustomStyles(settings)}
      <article className="news" itemScope itemType="http://schema.org/Article">
        <section itemProp="articleBody">
          <div className="header-container">
            <div className="page_titles">
              <Breadcrumbs pageContext={pageContext} />
              <Heading pageTitle>{currentNews.title}</Heading>
            </div>
          </div>
          {currentNews.content && renderHtmlBlocks(currentNews.content)}
        </section>
      </article>
    </Layout>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query NewsById($id: String!, $language: String!) {
    currentNews: wpPost(id: { eq: $id }) {
      content
      title
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
